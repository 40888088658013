import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import TermsAndConditions from 'components/@termsandconditions';

function TermsAndConditionsPage() {
  return (
    <Layout hideDownload={true}>
      <Seo title="Pintarnya Kerja | Ketentuan Layanan" />
      <TermsAndConditions />
    </Layout>
  );
}

export default TermsAndConditionsPage;
